<template>
    <div class="input__container" :class="{ 'input__container--focused': isFocused}">
        <input type="text" class="input--no-style input" placeholder="WHAT WOULD YOU LIKE TO SEARCH" v-model="inputText" @focus="onFocus" @blur="onBlur" @keydown.up="moveSelectionUp" @keydown.down="moveSelectionDown" @keydown.enter="selectResult">
        <svg class="icon__container" :class="{ 'icon__container--focused': isFocused}" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 21L16.65 16.65M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z" stroke="#cfcfcf" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" 
            :class="{ 'icon--focused' : isFocused, 'input__icon' : true }"/>
        </svg>
        <div v-show="inputText.length != 0" class="results__container" @click.stop="">
            <a href="" class="results__item">Prova 1</a>
            <a href="" class="results__item">Prova 2</a>
            <a href="" class="results__item">Prova 3</a>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                inputText: "",
                isFocused: false,
                selectedIndex: -1,
                results: [
                    { id: 1, text: 'example 1' },
                    { id: 2, text: 'example 2' },
                    { id: 3, text: 'example 3' },
                ]
            }
        },

        methods: {
            onFocus(){
                this.isFocused = true;
            },
            onBlur(){
                this.isFocused = false;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .results__item{
        width: 100%;
        text-decoration: none;
        padding: 16px 32px;
        &:hover, &:focus{
            background-color: var(--gray150);
        }
        color: black;
        font-size: 0.9rem;
        font-weight: 500;
    }
    .results__container{
        position: absolute;
        background-color: white;
        left: 0;
        top: 120%;
        /* transform: translateX(10px); */
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        //box-shadow: 4px 4px 9px rgba(151, 151, 151, 0.5);
        border: 2px solid rgb(228, 228, 228);
        border-radius: 10px;
        padding: 20px 0px;
    }
    .input--no-style{
        border: none;
        outline: none;
    }

    .input__container{
        width: 30rem;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 24px 32px;

        background: #FFFFFF;
        /* Accent/AI-500 */

        border: 2px solid #cfcfcf;
        //box-shadow: 0px 8px 25px 20px rgba(135, 135, 135, 0.27);
        border-radius: 10px;
        transition: all 0.6s ease;
        &--focused{
            border: 2px solid var(--accent500);
        }
    }

    .input{
        width: 100%;
        height: 20px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        font-size: 0.9rem;
        letter-spacing: 0.08em;

        /* Gray/600 */

        color: var(--gray600);
        &__icon{
            transition: all 0.2s ease;
            
        }
        &--focused{
            
        }
    }
    .icon__container{
        transition: all 0.4s ease;
        transform: scale(1.15);
        &--focused{
            transition: all 0.4s ease;
            transform: scale(1);
        }
    }

    .icon--focused{
        stroke: #E7217A;
        transition: all 0.2s ease;
        transform: scale(1);
    }

    @media (max-width: 600px) {
        .input__container{
            width: unset;
            padding: 12px 16px;
            input{
                font-size: 0.7rem;
            }
        }
    }

    @media (max-width: 700px) {
        .input__container{
            width: unset;
        }
    }

    @media (min-width: 1600px) {
        .input__container{
            width: 30rem;
        }
    }

    @media (min-width: 1920px) {
        .input__container{
            width: 40rem;
        }
    }


</style>