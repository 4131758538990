<template>
    <div>
        <section class="faq__section-1">
                <div class="faq__image-container">
                    <img src="../../../../assets/demoFaqImage.png" alt="">
                </div>
                <div class="faq__header">
                    <span class="faq__subtitle">Smart FAQ</span>
                    <span class="faq__title">How can We<br>help You</span>
                    <span class="faq__description" style="margin-bottom: 64px">Test our knowledge base instantly<br class="br-0"> and get<br class="br-1"> the answers you need</span>
                    <!-- <input type="text"> -->
                    <InputSearch />
                </div>
        </section>

    </div>
</template>

<script>
    import InputSearch from "../base/InputSearch.vue"
    
    export default {
        components: {
            InputSearch
        }    
    }
</script>

<style scoped lang="scss">
.br-0{
    display: none;
}
    .faq__section-1{
        display: flex;
        flex-direction: row;
        .faq__image-container{
        img{
            object-fit: cover;
            border-radius: 0 10px 10px 0;
        }
        }
        .faq__header{
            padding: 0px 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .faq__subtitle{
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 32px;
                color: var(--accent500);
                margin-bottom: 16px;
                animation: 2 ease all;
                &--transition{
                    animation: 0.5s ease all;
                    transform: translateX(0px);
                    opacity: 1;
                }
                animation: faq__subtitle-animation;
                animation-duration: 1.2s;
            }

            @keyframes faq__subtitle-animation{
                from {
                    transform: translateX(-25px); opacity: 0;
                }
                to{
                    transform: translateX(0); opacity: 1;
                }
            }
            .faq__title{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 60px;
                line-height: 80px;
                margin-bottom: 60px;
                color: var(--black);
            }

            .faq__description{
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                color: var(--gray600);
            }
        }

        @media (max-width: 1000px) {
            
            .section{
            }

            .faq__header{
                margin: auto;
                padding: 0 30px;
            }
            
            .faq__image-container{
                display: none;
            }
        }

        @media (max-width: 1500px) {
            .faq__image-container{
                img{
                    width: 500px;
                    height: 600px;
                }
            }
        }

        @media (max-width: 1300px) {
            header{
                height: 120px;
                .aiku-logo{
                    width: 40px;
                    height: 40px;
                }
            }
        }


        @media (max-width: 600px) {
            .faq__image-container{
                display: none;
            }
            .faq__title{
                    font-size: 36px !important;
                    line-height: unset !important;
                    margin-bottom: 2rem !important;
                }
                .faq__description{
                    font-size: 16px !important;
                    margin-bottom: 2rem !important;
                }
                .br-1{
                    display: none;
                }
        }

    }
</style>